import React, { ButtonHTMLAttributes, InputHTMLAttributes } from 'react';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  className?: string;
}

export const Button: React.FC<ButtonProps> = ({ children, className = '', ...props }) => (
  <button className={`${className}`} {...props}>
    {children}
  </button>
);

export const Input: React.FC<InputHTMLAttributes<HTMLInputElement>> = (props) => (
  <input {...props} className="border border-gray-300 rounded px-3 py-2 w-full" />
);

interface AlertProps {
  children: React.ReactNode;
}

export const Alert: React.FC<AlertProps> = ({ children }) => (
  <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded">
    {children}
  </div>
);
